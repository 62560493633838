<template>
<Modal/>
<div class="button-group" v-show="store.isAnimationEnded">
    <button class="modal-button" @click="store.isModalVisible=true" v-show="store.selectedElement!=null"><i class="fa-solid fa-info"></i></button>
    <div class="button-group-element" id="scroll-group" v-for="(item,index) in jsonData" v-bind:key="item" v-bind:index="index" v-show="item.category_name === store.selectedCategory && store.isCategoryLoaded === true">
        <div class="arrow-buttons">
            <button id="arrow-left" class="arrow" v-bind:index="store.selectedCategoryIndex" @click="scrollLeft(index)"><i class="fa-solid fa-chevron-left"></i></button>
            <button id="arrow-right" class="arrow"  v-bind:index="store.selectedCategoryIndex" @click="scrollRight(index)"><i class="fa-solid fa-chevron-right"></i></button>
        </div>
        <button class="hud-button" :class="{'active': index === store.selectedElementIndex}" v-for="(item,index) in item.elements" v-bind:key="item" v-bind:index="index" @click="showObject(item.element_name,item,index)">{{item.display_name}}</button>
    </div>
    <LoadingSpinner/>
    <div class="button-group-category">
        <button class="hud-button" :class="{'active' : index === store.selectedCategoryIndex}" v-for="(item,index) in jsonData" v-bind:key="item" v-bind:index="index" @click="setSelectedCategory(item.category_name,index)">{{item.category_name}}</button>
    </div>  
</div>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Modal from '@/components/Modal.vue';
import data from '@/data/data.json';
import {useMainStore} from '@/store/mainStore';
import {objectPool} from '@/core/core';
import {showObject,showAnnouncer} from '@/core/core';

let store;
let previousModel;
let modelPaths;





export default{
    name:'ButtonGroup',
    components:{
        LoadingSpinner,
        Modal
    },
    setup(){
        store = useMainStore();
    },
    data: ()=>{
        return{
            jsonData : data,
            store : store 
        }
    },
    methods:{
        setSelectedCategory: function(category,index){
            store.setCategory(category, index);
            store.setElement(null,null); //Avoid carrying selectedElement from previous SelectedCategory
            showObject(category.toLowerCase()+'_announcer');
        },
        setSelectedElement: function(element,index){
            store.setElement(element,index);
        },
        showObject: function(elementName,element,index){
            showObject(elementName);
            store.setElement(element,index);
        },
        scrollLeft: function(index){
            document.getElementsByClassName('button-group-element')[index].scrollLeft -= 180
        },
        scrollRight: function(index){
            document.getElementsByClassName('button-group-element')[index].scrollLeft += 180
        }
    }
}
</script>

<style scoped>
.button-group{
    width: 280px;
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    bottom:0;
    z-index:1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hud-button{
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color:#fff;
    height: 40px;
    margin-right: 2.5px;
    margin-left: 2.5px;
    margin-top:10px;
    flex-shrink: 0;
}

.modal-button{
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    align-self: flex-end;
}

.active{
   background: linear-gradient(66.04deg, #890EB8 0%, #DF306A 100%); 
}

.button-group-element{
    scroll-behavior: smooth;
    width: 260px;
    display: flex;
    overflow-x: scroll;
}

.button-group-element::-webkit-scrollbar {
  display: none;
}

.arrow-buttons{
    position:absolute;
    left:50%;
    transform: translateX(-50%);
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 340px;
}

.arrow{
    min-width: 40px!important;
    width: 40px!important;
    height: 40px;
    border-radius: 40px;
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color:#fff;
}

</style>