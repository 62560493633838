<template>
    <div class="modal-window" v-show="store.isModalVisible">
        <h1>{{store.selectedElementName}}</h1>
        <p>{{store.selectedElementDescription}}</p>
        <a v-show="store.selectedElementUrl!=null" class="order_link" :href="store.selectedElementUrl" target="_blank">I Want this!</a>
        <button @click="store.isModalVisible=false">Close</button>
    </div>
</template>

<script>
import { useMainStore } from '@/store/mainStore';

let store;
export default {
    name: 'Modal',
    setup(){
        store = useMainStore()
    },
    data: ()=>{
        return{
            store: store,
            currentElement: store.selectedElement
        }
    }
}
</script>

<style scoped>
.modal-window{
        position: absolute;
        z-index:1;
        display: flex;
        flex-direction: column;
        width: 320px;
        padding-top: 5px;
        padding-bottom: 20px;
        background-color: #ddd;
        align-items: center;
        border-radius:8px;
        color:#fff;
        background: linear-gradient(152.97deg, rgba(21, 21, 21, 0.8) 0%, rgba(21, 21, 21, 0.8) 100%);
        backdrop-filter: blur(20px);
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
}


.modal-window>h1{
    padding: 15px;
}

.modal-window>p{
    padding:15px;
}

.modal-window>button{
    width: 120px;
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color:#fff;
    padding: 10px;
    font-size:16px;
    margin-top: 10px;
}
.order_link{
    width: 120px;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #fff;
    background-color: #7fc15e;
    text-decoration: none;
}
</style>