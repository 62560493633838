<template>
<div id="main">
<LoadingScreen/>
<ButtonGroup/>
</div>
</template>

<script>
import ButtonGroup from '@/components/ButtonGroup.vue';
import LoadingScreen from '@/components/LoadingScreen.vue'
import data from '@/data/data.json';
import {startOnirix, loadAnnouncers,loadMisc,loadEnv} from '@/core/core.js';

let announcers = [];

export default {
  name: 'App',
  components: {
    ButtonGroup,
    LoadingScreen
  },
  mounted(){
    startOnirix();
    data.forEach(element => {
      announcers.push(element.category_announcer)
    });
    loadAnnouncers(announcers);
    loadMisc();
    loadEnv();
  }
}
</script>

<style>
#app {
  margin:0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color:#2c3e50;
}

#load{
  display: none;
}

#foodgroup{
  display: none;
}

#cocktailsgroup{
  display: none;
}
</style>
