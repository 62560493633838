import { defineStore } from "pinia";
import data from '@/data/data.json';
import {loadCategory,switchAnim} from '@/core/core';


export const useMainStore = defineStore('mainStore',{
    state:()=>{
        return{
            selectedCategory: null,
            selectedCategoryIndex: null,
            selectedElement: null,
            selectedElementIndex: null,
            selectedElementName: null,
            selectedElementDescription: null,
            selectedElementUrl: null,
            currentModelName: 'logo',
            previousModelName: null,
            categoryElements: [],
            categoryElementPaths:[],
            isCategoryLoaded: false,
            isTracked: false,
            isModalVisible: false,
            isAnimationEnded:false,
            isLoadingScreen:true,
            canStart:false
        };
    },
    actions:{
        setCategory(getCategory = "", getCategoryIndex = null){
            this.categoryElementPaths = [];
            this.selectedCategory = getCategory;
            this.selectedCategoryIndex = getCategoryIndex;
            this.categoryElements = data[this.selectedCategoryIndex].elements;
            this.categoryElements.forEach(element => {
                this.categoryElementPaths.unshift(element.model_path)
            });
            loadCategory(this.categoryElements);
        },
        setElement(getElement = "", getElementIndex = ""){
            this.selectedElement = getElement;
            this.selectedElementIndex = getElementIndex;
            this.selectedElementName = this.selectedElement!=null ? this.selectedElement.display_name : 'null';
            this.selectedElementDescription = this.selectedElement!=null ? this.selectedElement.element_description : 'null';
            this.selectedElementUrl = this.selectedElement!=null ? this.selectedElement.order_link : 'null';
        },
        setModel(getCurrentModel="", getPreviousModel=""){
            this.previousModelName = getPreviousModel;
            this.currentModelName = getCurrentModel;
            if(this.currentModelName === this.previousModelName){
                this.currentModelName = getCurrentModel;
            }else{
               console.log('a') 
            }
        }
    },
    getters:{
        showSelectedCategory: (state) => state.selectedCategory,
        showSelectedElement: (state) => state.selectedElement
    }
})