import * as THREE from 'three';
import OnirixSDK from "@/sdk/ox-sdk";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader.js';
import { useMainStore } from '@/store/mainStore';
import gsap from 'gsap';
import {ObjectControls} from 'threejs-object-controls';

import Stats from "stats-js";

export let camera,renderer;
let scene = new THREE.Scene();
let hdrEquirect;
let store;
let controls;
let BaseMixer, BaseAction;
var stats = new Stats();
stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
document.body.appendChild( stats.dom );


const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjcwOTAsInByb2plY3RJZCI6MjI5NTQsInJvbGUiOjMsImlhdCI6MTY0NDUxMTUwN30.ivSPFWm0EsMxa9Hiko83Fvm_NBi8k4gVD86Fsx-i76A";

export const videoElement = document.getElementById("video");
videoElement.src = './video/base.mp4';
const videoTexture = new THREE.VideoTexture(videoElement);
videoTexture.flipY = false; //Upside down video texture fix

const dracoloader = new DRACOLoader();
dracoloader.setDecoderPath("./draco/gltf/");
dracoloader.setDecoderConfig( { type: 'wasm' } );

const manager = new THREE.LoadingManager();
const loader = new GLTFLoader(manager);
loader.setDRACOLoader(dracoloader);



manager.onStart = function ( url, itemsLoaded, itemsTotal ) {
    store = useMainStore();
	console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
    store.isCategoryLoaded = false;
};
manager.onLoad = function ( ) {
    store = useMainStore();
    store.isCategoryLoaded = true;
	console.log( 'Loading complete!');
};
manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
	console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
};
manager.onError = function ( url ) {
	console.log( 'There was an error loading ' + url );
};


let objectPool = [];
export let namePool = [];

export function flushLast(){
    //pending implementation
}



const misc = ['./misc/base.glb','./misc/base_video.glb','./misc/logo.glb'];
export function loadMisc(){
    videoElement.load();
    misc.forEach(element => {
        loader.load(element, (gltf)=>{
            gltf.scene.name = element.split('/')[element.split('/').length-1].split('.')[0];
            gltf.scene.scale.set(5,5,5);

            gltf.scene.traverse((child)=>{
                if(child.name && child.name === 'Video'){
                    child.material.map = videoTexture;
                }
                if (child.name && child.name === 'Occluder') {
                    child.material.colorWrite = false;
                }  
            });
            
            if(gltf.scene.name === 'base'){
                BaseMixer = new THREE.AnimationMixer(gltf.scene);
                BaseAction = BaseMixer.clipAction(gltf.animations[0]);
                BaseAction.clampWhenFinished = true;
                BaseAction.loop = THREE.LoopOnce;
            }
            gltf.scene.visible = false;
            scene.add(gltf.scene);
        })
    });    
}



export function loadEnv(){
    hdrEquirect = new RGBELoader(manager).load( './misc/texture.hdr', function () {
        hdrEquirect.mapping = THREE.EquirectangularReflectionMapping;
    });
    scene.environment = hdrEquirect;
}

const glassMaterial = new THREE.MeshPhysicalMaterial( {
    transmission: 1,
    opacity: 1,
    metalness: 0.3,
    roughness: 0.10,
    ior: 1.5,
    color: 0xffffff,
    envMap: scene.environment,
    envMapIntensity: 1,
    side: THREE.DoubleSide,
    depthWrite: false,
    transparent: true
});

const iceMaterial = new THREE.MeshPhysicalMaterial( {
    transmission: 0.47,
    opacity: 0.95,
    metalness: 0.7,
    roughness: 0.26,
    ior: 1.3,
    color: 0xffffff,
    envMap: scene.environment,
    envMapIntensity: 1,
    side: THREE.DoubleSide,
    depthWrite: false,
    transparent: true
} );



export function startExperience(){
    const logo = scene.getObjectByName('logo')
    logo.position.y = -5;
    logo.scale.set(0,0,0);
    scene.getObjectByName('base_video').visible = true;
    setTimeout(()=>{videoElement.play();},1000);
    setTimeout(()=>{scene.getObjectByName('base_video').visible = false;},6500);
    setTimeout(()=>{
        BaseAction.play();
        scene.getObjectByName('base').visible = true;
    },6500);
    setTimeout(()=>{
        logo.visible=true;
        const tl = gsap.timeline();
        tl.to(logo.position,{y:0,duration:.2})
        tl.to(logo.scale,{x:2,y:2,z:2,duration:.3})
    },7500);
    setTimeout(()=>{
    store.isAnimationEnded = true},8000)
}

export function switchAnim(oldElement,newElement){
    const tl = gsap.timeline();
    tl.to(scene.getObjectByName(oldElement.scale),{x:0,y:0,z:0,duration:.4});
    tl.to(scene.getObjectByName(oldElement.scale),{y:-.7,duration:.4});
    tl.to(scene.getObjectByName(newElement.scale).position,{y:1.5,duration:.4});
    tl.to(scene.getObjectByName(newElement.scale),{x:1,y:1,z:1,duration:.4});
}

export function loadAnnouncers(announcers){
    announcers.forEach(element=>{
        loader.load(element, (gltf)=>{
            gltf.scene.name= element.split('/')[element.split('/').length-1].split('-')[0]+'_announcer';
            gltf.scene.visible = false;
            gltf.scene.scale.set(1.5,1.5,1.5);
            scene.add(gltf.scene);
        })
    })
}

export function loadCategory(category){
    scene.remove(...objectPool)
    namePool = [];
            category.forEach(element => {
            namePool.push(element.element_name);
            loader.load(element.model_path, (gltf)=>{
                gltf.scene.name = element.element_name;
                gltf.scene.visible = false;
                gltf.scene.scale.set(0,0,0);
                gltf.scene.position.set(0,-1,0)

                const glass = gltf.scene.children.find((child) => child.name === "glass");
                const ice = gltf.scene.children.find((child) => child.name === "ice");

                if(glass){
                    glass.material = glassMaterial;
                }
                if(ice){
                    ice.material = iceMaterial;
                }
                scene.add(gltf.scene);
                objectPool.push(gltf.scene);
            })
        });
}

let lastShown;

function hideModel(model){
    const tl = gsap.timeline();
    tl.to(model.scale,{x:0,y:0,z:0,duration:.2})
    tl.to(model.position,{y:-.7,duration:.2})
}

function showModel(model){
    const tl = gsap.timeline();
    tl.to(model.scale,{x:1,y:1,z:1,duration:.2})
    tl.to(model.position,{y:.3,duration:.2})
    controls.enableHorizontalRotation();
    controls.setObjectToMove(model);
}

export function showObject(object){
    console.log(object);
    if(lastShown){
        scene.getObjectByName(object).visible = true;
        hideModel(lastShown);
        showModel(scene.getObjectByName(object));
        lastShown = scene.getObjectByName(object);
    }else{
        console.log(object)
        scene.getObjectByName('logo').visible = false;
        showModel(scene.getObjectByName(object));
        scene.getObjectByName(object).visible = true;
        lastShown = scene.getObjectByName(object);
    }
    console.log(scene)
}

function setupRenderer(rendererCanvas) {
    const width = rendererCanvas.width;
    const height = rendererCanvas.height;
    // Initialize renderer with rendererCanvas provided by Onirix SDK
    renderer = new THREE.WebGLRenderer({ canvas: rendererCanvas, alpha: true, antialias:true, powerPreference: 'high-performance' });
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(width, height);
    renderer.setPixelRatio(window.devicePixelRatio >= 2 ? 2 : 1)
    // Ask Onirix SDK for camera parameters to create a 3D camera that fits with the AR projection.
    const cameraParams = OX.getCameraParameters();
    camera = new THREE.PerspectiveCamera(cameraParams.fov, cameraParams.aspect, 0.1, 1000);
    camera.matrixAutoUpdate = false;
    // Add some lights
    const ambientLight = new THREE.AmbientLight(0xcccccc, 0.4);
    scene.add(ambientLight);
    const hemisphereLight = new THREE.HemisphereLight(0xbbbbff, 0x444422);
    scene.add(hemisphereLight);    
}

function updatePose(pose) {
    let modelViewMatrix = new THREE.Matrix4();
    modelViewMatrix = modelViewMatrix.fromArray(pose);
    camera.matrix = modelViewMatrix;
    camera.matrixWorldNeedsUpdate = true;
}

function onResize() {

    const width = renderer.domElement.width;
    const height = renderer.domElement.height;
    const cameraParams = OX.getCameraParameters();
    camera.fov = cameraParams.fov;
    camera.aspect = cameraParams.aspect;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);
}

function render() {

    renderer.render(scene, camera);

}

const clock = new THREE.Clock();

function renderLoop() {
    const delta = clock.getDelta();
    stats.begin();
    BaseMixer.update(delta);
    render();
    requestAnimationFrame(() => renderLoop());
    stats.end();
}

let OX = new OnirixSDK(token);

let config = {
    mode: OnirixSDK.TrackingMode.Image
}

let FistTimeTracked = false;

export function startOnirix(){
store = useMainStore();
OX.init(config).then(rendererCanvas => {
    setupRenderer(rendererCanvas);
    controls = new ObjectControls(camera, rendererCanvas, scene);
    controls.disableHorizontalRotation();
    renderLoop();
    OX.subscribe(OnirixSDK.Events.OnDetected, function (id) {
        console.log("Detected Image: " + id);   
        if(store.isLoadingScreen===true){
            scene.visible=true;
            store.canStart= true; 
            scene.getObjectByName('base_video').visible = true;
        }else{
            scene.visible = true;
        }
        /*if(FistTimeTracked===true){
            scene.visible = true;
            store.isTracked = true;
        }else{
            scene.visible = true;
            store.isTracked = true;
            FistTimeTracked = true;
            startExperience(); 
        }*/
        scene.background = new THREE.VideoTexture(OX.getCameraFeed());
    });

    OX.subscribe(OnirixSDK.Events.OnPose, function (pose) {
        updatePose(pose);
    });

    OX.subscribe(OnirixSDK.Events.OnLost, function (id) {
        if(store.isLoadingScreen===true){
            scene.visible=false;
            store.isTracked = false;
            store.canStart= false; 
            scene.getObjectByName('base_video').visible = true;
            scene.background = null;
        }else{
            scene.visible = false;
            store.isTracked = false;
            scene.background = null
        }
        /*;*/
    });

    OX.subscribe(OnirixSDK.Events.OnResize, function () {
        onResize();
    });

}).catch((error) => {

    // An error ocurred, chech error type and display it
    switch (error.name) {

        case 'INTERNAL_ERROR':
          console.log("internal error");
            break;

        case 'CAMERA_ERROR':
          console.log("camera error");
            break;

        case 'SENSORS_ERROR':
          console.log("sensor error");
            break;

        case 'LICENSE_ERROR':
          console.log("license error");
            break;
    }})
}