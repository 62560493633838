<template>
    <div class="steps" v-show="store.isLoadingScreen">
        <div class="step" v-show="currentStep===0">
            <h1 class="title">Welcome</h1>
            <img src="/img/first_step.png" alt="First step">
            <p class="text">Use your phone in vertical position 
                and press the buttons to interact 
                with the virtual menu of Metaterrace</p>
        </div>
        <div class="step" v-show="currentStep===1">
            <h1 class="title">Fit &amp; Place</h1>
            <img src="/img/second_step.png" alt="Second step">
            <p class="text">Begin the experience following these steps, 
                remember that a correct view sitting is recommended</p>
        </div>
        <div class="step" v-show="currentStep===2">
            <h1 class="title">Sound</h1>
            <img src="/img/third_step.png" alt="Third step">
            <p class="text">Turn up the volume or use headphones for a better experience</p>
        </div>
        <div class="pagination">
            <button @click="prevStep()" class="arrowButton"><i class="fa-solid fa-chevron-left"></i></button>
            <button class="startButton" v-show="store.canStart" @click="start()">START!</button>
            <button @click="nextStep()" class="arrowButton"><i class="fa-solid fa-chevron-right"></i></button>  
        </div>
        <div class="dots">
            <span class="dot" :class="(currentStep===0) ?'current' : ''"><i class="fa-solid fa-circle"></i></span>
            <span class="dot" :class="(currentStep===1) ?'current' : ''"><i class="fa-solid fa-circle"></i></span>
            <span class="dot" :class="(currentStep===2) ?'current' : ''"><i class="fa-solid fa-circle"></i></span>
        </div>
    </div>
</template>

<script>
import {useMainStore} from '@/store/mainStore';
import {startExperience} from '@/core/core';

let store;
export default{
  name:'LoadingScreen',
  setup(){
      store = useMainStore()
  },
  data: ()=>{
      return{
          currentStep:0,
          store:store
      }
  },
  methods:{
      nextStep: function(){
          if(this.currentStep>=2){
              this.currentStep = 0;
          }else{
              this.currentStep+=1;
          }
      },
      prevStep: function(){
          if(this.currentStep<=0){
              this.currentStep = 2;
          }else{
              this.currentStep-=1;
          }
      },
      start: function(){
          startExperience();
          store.isLoadingScreen = false;
      }
  }  
}
</script>

<style scoped>
.steps{
    position: absolute;
    z-index: 1;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 320px;
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    border-radius:12px;
    color:#333;
}
.step{
    height: 360px;
}
.pagination{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.dots{
    padding: 15px;
    color:#fff;
}
.title{
    padding: 15px;
}
.text{
    padding: 15px;
}
.dot{
    padding: 2px;
    font-size:12px;
}

.current{
    color:#333;
}
.arrowButton{
    min-width: 40px!important;
    width: 40px!important;
    height: 40px;
    border-radius: 40px;
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color:#fff;
}
.startButton{
    border-radius: 8px ;
    background: rgba( 255, 255, 255, 0.3 );
    backdrop-filter: blur( 8px );
    -webkit-backdrop-filter: blur( 8px );
    border: 1px solid rgba( 255, 255, 255, 0.3 );
    color:#fff;
}
</style>
