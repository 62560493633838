<template>
<div class="loadingio-spinner-pulse-diabfbay7a8" v-show="store.isCategoryLoaded===false"><div class="ldio-38sm65atd6d">
<div></div><div></div><div></div>
</div></div>
</template>

<script>
import {useMainStore} from '@/store/mainStore';
let store;
export default {
   name: 'LoadingSpinner',
   setup(){
        store = useMainStore();
    },
   data: ()=>{
       return{
           store:store
       }
   }
}
</script>

<style scoped>
@keyframes ldio-38sm65atd6d-1 {
  0% { top: 18px; height: 64px }
  50% { top: 34px; height: 32px }
  100% { top: 34px; height: 32px }
}
@keyframes ldio-38sm65atd6d-2 {
  0% { top: 22px; height: 56px }
  50% { top: 34px; height: 32px }
  100% { top: 34px; height: 32px }
}
@keyframes ldio-38sm65atd6d-3 {
  0% { top: 26px; height: 48px }
  50% { top: 34px; height: 32px }
  100% { top: 34px; height: 32px }
}
.ldio-38sm65atd6d div { position: absolute; width: 12px }.ldio-38sm65atd6d div:nth-child(1) {
  left: 19px;
  background: rgba( 0, 0, 0, 0.6 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  animation: ldio-38sm65atd6d-1 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.2s
}
.ldio-38sm65atd6d div:nth-child(2) {
  left: 44px;
  background: rgba( 0, 0, 0, 0.6 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  animation: ldio-38sm65atd6d-2 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: -0.1s
}
.ldio-38sm65atd6d div:nth-child(3) {
  left: 69px;
  background: rgba( 0, 0, 0, 0.6 );
  backdrop-filter: blur( 8px );
  -webkit-backdrop-filter: blur( 8px );
  animation: ldio-38sm65atd6d-3 1s cubic-bezier(0,0.5,0.5,1) infinite;
  animation-delay: undefineds
}

.loadingio-spinner-pulse-diabfbay7a8 {
  width: 36px;
  height: 36px;
  display: inline-block;
  overflow: hidden;
  background: none;
  align-self: center;
}
.ldio-38sm65atd6d {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.36);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-38sm65atd6d div { box-sizing: content-box; }
/* generated by https://loading.io/ */

</style>